<template>
  <div class="contact">
    <div class="container flex justify-center content-center direction-column h-100">
      <PageTitle :title="'Kontakt'"/>
    </div>
  </div>
  <div class="section">
    <div class="container">
      <Header :title="'Wybierz formę kontaktu'" />
    </div>
    <div class="container flex justify-center vertical-center direction-column--md">
      <div class="hexagon__container" v-for="contact in contacts" :key="contact">
        <div class="hexagon">
          <h3 class="hexagon__title">{{ contact.title }}</h3>
          <a class="hexagon__link" :href="contact.link" v-if="contact.link"></a>
          <img :src="require(`@/assets/${contact.img}`)"
              alt="{{ contact.title }}"
              class="contact__img">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue';
import PageTitle from '../components/PageTitle';
import Header from '../components/Header';

export default {
  name: 'Contact',
  components: { PageTitle, Header },
  setup() {
    const contacts = [
      {
        title: 'Telefon',
        link: 'tel:+48787951751',
        img: 'phone.svg',
      },
      {
        title: 'E-mail',
        link: 'mailto:kontakt@adamkanigowski.pl',
        img: 'email.svg',
      },
      // {
      //   title: 'Formularz kontaktowy',
      // },
    ];

    onMounted(() => {
      document.title = 'Kontakt - adamkanigowski.pl';
    });

    return { contacts };
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/vars';

.contact {
  background: url('../assets/contact.jpg') no-repeat;
  background-size: cover;
  padding-top: 8rem;
  padding-bottom: $padding-4;

  @media #{$lg-min}  {
    background-position-y: -200px;
  }

  &__img {
    fill: $background-color;
    width: auto;
    height: 6rem;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: .05;
    z-index: 0;
    transform: translate(-50%, -50%);

    @media #{$md-min} {
      height: 10rem;
    }
  }
}

.hexagon {
  &__link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    outline: none;
  }

  &__title {
    top: 50%;
  }
}
</style>
